import { template as template_b04ef2168fba42d3b61ab4fa9cd0de5a } from "@ember/template-compiler";
const FKControlMenuContainer = template_b04ef2168fba42d3b61ab4fa9cd0de5a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
