import { template as template_ef701dbb2b5642939bcce43527e06fee } from "@ember/template-compiler";
const SidebarSectionMessage = template_ef701dbb2b5642939bcce43527e06fee(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
