import { template as template_07e4a903e840497fb0b2e2bb04017752 } from "@ember/template-compiler";
const FKLabel = template_07e4a903e840497fb0b2e2bb04017752(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
